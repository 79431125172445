import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

import "core-js/modules/es.array.push.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c(VApp, {
    staticClass: "grey lighten-5",
    staticStyle: {
      "height": "40px"
    }
  }, [_c(VContainer, {
    staticClass: "pa-0 grey lighten-4",
    staticStyle: {
      "max-width": "100%",
      "width": "100%",
      "position": "sticky",
      "z-index": "10",
      "top": "0px"
    }
  }, [_c(VCard, {
    staticClass: "ma-0",
    attrs: {
      "elevation": "1",
      "tile": "",
      "color": "primary",
      "id": "menu"
    }
  }, [_c(VCardTitle, {
    staticClass: "py-2 pl-2 white--text",
    attrs: {
      "primary-title": ""
    }
  }, [_c(VBtn, {
    staticClass: "mr-3",
    staticStyle: {
      "width": "30px",
      "height": "30px"
    },
    attrs: {
      "fab": ""
    }
  }, [_c('img', {
    staticStyle: {
      "width": "30px"
    },
    attrs: {
      "src": require("./images/logo-96.png"),
      "alt": ""
    }
  })]), _vm._v(" 学校管理平台 "), _c(VSpacer), _vm.$route.path != '/' ? _c(VBtn, {
    staticClass: "primary--text px-2 mr-2",
    attrs: {
      "color": "white",
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          path: '/'
        });
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-home")]), _c('span', [_vm._v("首页")])], 1) : _vm._e(), !_vm.$sopen.isWechat() ? _c(VBtn, {
    staticClass: "error--text px-2",
    attrs: {
      "color": "white",
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.openOut();
      }
    }
  }, [_vm._v(" 退出 "), _c(VIcon, {
    staticClass: "ml-1"
  }, [_vm._v("mdi-login-variant")])], 1) : _vm._e()], 1)], 1)], 1), _c(VDialog, {
    attrs: {
      "max-width": "390"
    },
    model: {
      value: _vm.dialogLogin,
      callback: function ($$v) {
        _vm.dialogLogin = $$v;
      },
      expression: "dialogLogin"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "text-h6 pl-3"
  }, [_c(VIcon, {
    staticClass: "pr-4",
    attrs: {
      "color": "error"
    }
  }, [_vm._v("mdi-alert")]), _vm._v(" 系统提示 ")], 1), _c(VDivider), _c(VCardText, {
    staticClass: "pa-5",
    staticStyle: {
      "font-size": "18px",
      "min-height": "100px"
    }
  }, [_vm._v("你确认要退出社区吗？ ")]), _c(VCardActions, [_c(VBtn, {
    staticClass: "mr-4",
    attrs: {
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        _vm.dialogLogin = false;
      }
    }
  }, [_c(VIcon, {
    staticClass: "pr-3"
  }, [_vm._v("mdi-close")]), _vm._v(" 取消 ")], 1), _c(VSpacer), _c(VBtn, {
    attrs: {
      "elevation": "0",
      "color": "error"
    },
    on: {
      "click": _vm.loginout
    }
  }, [_c(VIcon, {
    staticClass: "pr-3"
  }, [_vm._v("mdi-check")]), _vm._v(" 确认退出 ")], 1)], 1)], 1)], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };