require("./images/logo-96.png");

export default {
  data() {
    return {
      dialogLogin: false
    };
  },

  created() {},

  mounted() {},

  methods: {
    openOut() {
      this.dialogLogin = true;
      return false;
    },

    loginout() {
      this.$sopen.loginout();
    }

  }
};